import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const autoGobdCheckTermModule = namespace('autoGobdCheckTerm');
@Component({
  components: {},
})
export default class OrganizationExportView extends Vue {
  @autoGobdCheckTermModule.Getter('getAutoGobdCheckTerms')
  private getAutoGobdCheckTerms?: any;
  @autoGobdCheckTermModule.Action('getAutoGobdCheckTerms')
  private actionGetAutoGobdCheckTerms?: any;

  get autoGobdCheckTerms() {
    //return this.getAutoGobdCheckTerms;
    return {
      items: [
        {
          id: '1111',
          organisation: 'fdfdf',
          customer: 'ddfsdf',
          downloadLink1: '',
          downloadLink2: '',
          exportDate: '11/11/2011',
        },
      ],
      isLoading: false,
      total: 2, //TODO remove when was made backend
      searchParams: {
        dataOption: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: true,
          mustSort: false,
        },
        filter: '',
        orClauseFieldsIds: ['ActionEmailMessage'],
      },
    };
  }

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('id'), value: 'id' },
      { text: this.$t('organisation'), value: 'organisation' },
      { text: this.$t('customer'), value: 'customer' },
      { text: this.$t('downloadLink1'), value: 'downloadLink1' },
      { text: this.$t('downloadLink1'), value: 'downloadLink1' },
      { text: this.$t('exportDate'), value: 'exportDate' },
      { text: this.$t('supplier'), value: 'supplierAccountName' },
      { text: this.$t('prio'), value: 'priority' },
    ];
    return headers;
  }

  created() {
    this.actionGetAutoGobdCheckTerms();
  }
}
